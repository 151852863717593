require('jquery.marquee')

export const news = _ => {
    if ($('.marquee')) {
        $('.marquee').marquee({
            speed: 60,
            gap: 0,
            delayBeforeStart: 0,
            direction: 'left',
            duplicated: true,
            startVisible: true
        })
    }
}
