const lazyload = () => {
    const b = document.getElementsByTagName('body')[0]
    const s = document.createElement('script')
    s.async = true
    s.defer = true
    s.src = 'https://cdn.jsdelivr.net/npm/vanilla-lazyload@10.19.0/dist/lazyload.min.js'
    window.lazyLoadOptions = [{ elements_selector: '.lazy' }, {}]
    s.classList.add('foo')
    b.appendChild(s)
}

export { lazyload }
