import { tns } from 'tiny-slider/src/tiny-slider';
import lottie from 'lottie-web/build/player/lottie.min';

require('@fancyapps/fancybox');

const smoothScroll = e => {
    jQuery('a[href^="#"]').bind('click', function (e) {
        e.preventDefault();
        const target = this.hash;
        const $target = jQuery(target);

        let scroll = this.dataset.scroll;
        if (scroll == undefined || scroll == null || scroll == '')
            scroll = 0;

        if (!$target.length)
            return;

        jQuery('html, body').stop().animate({
            'scrollTop': $target.offset().top - scroll
        }, 500);
    });
};

const languageScroll = _ => {
    const $header = jQuery('#globalHeader');

    jQuery(window).on('scroll', function () {
        let navHeight;

        if (jQuery(window).width() <= 768) {
            navHeight = jQuery(window).height() + 100;
        } else {
            navHeight = jQuery(window).height();
        }

        if (jQuery(window).scrollTop() > navHeight) {
            $header.addClass('scroll');
        } else {
            $header.removeClass('scroll');
        }
    });
};

const navigation = e => {
    const navTrigger = document.querySelector('.navToggle');
    if (navTrigger) {
        if (e.target === navTrigger) {
            e.target.classList.toggle('open');
            e.target.parentNode.parentNode.classList.toggle('open');
            e.target.parentNode.querySelector('.main').classList.toggle('open');

            if (
                document.querySelector('.language').classList.contains('open')
            ) {
                document.querySelector('.language').classList.remove('open');

                document
                .querySelector('#globalHeader')
                .classList.remove('langOpen');
            }
        } else {
            if (navTrigger.classList.contains('open')) {
                navTrigger.classList.remove('open');
                navTrigger.parentNode.parentNode.classList.remove('open');
                navTrigger.parentNode
                          .querySelector('.main')
                          .classList.remove('open');
            }
        }
    }
};

const selectTab = (item, nav, e) => {
    Array.from(item.children).forEach(c => {
        c.classList.remove('active');
        let tab =
            e.target.getAttribute('data-target') ||
            e.target.parentNode.getAttribute('data-target');
        if (c === e.target || e.target.parentNode === c) {
            c.classList.add('active');
        }

        nav.forEach(tabs => {
            Array.from(tabs.children).forEach(t => {
                t.classList.remove('active');
                if (t.getAttribute('data-target') === tab) {
                    t.classList.add('active');
                }
            });
        });
    });
};

const tabs = e => {
    const tabContainer = document.querySelector('.tabs');

    if (tabContainer) {
        if (tabContainer.contains(e.target)) {
            const tabNavs = document.querySelectorAll('.tabNav');
            const contentNavs = document.querySelectorAll('.contentNav');
            const tabContainers = document.querySelectorAll('.tabContainer');
            const tabss = document.querySelectorAll('.tab');

            tabNavs.forEach(tabNav => {
                if (tabNav.contains(e.target)) {
                    const dataTarget = e.target.getAttribute('data-target');
                    if (
                        !document.querySelector(
                            `.tab[data-target="${dataTarget}"] .special`
                        )
                    ) {
                        Array.from(
                            document
                            .querySelector(
                                `.tab[data-target="${dataTarget}"]`
                            )
                            .querySelectorAll('.contentNav li')
                        ).forEach(i => i.classList.remove('active'));
                        Array.from(
                            document
                            .querySelector(
                                `.tab[data-target="${dataTarget}"]`
                            )
                            .querySelectorAll('.content')
                        ).forEach(i => i.classList.remove('active'));
                        document
                        .querySelector(`.tab[data-target="${dataTarget}"]`)
                        .querySelector('.contentNav li')
                        .classList.add('active');
                        document
                        .querySelector(`.tab[data-target="${dataTarget}"]`)
                        .querySelector('.content')
                        .classList.add('active');
                    }
                    selectTab(tabNav, tabContainers, e);
                }
            });

            contentNavs.forEach(contentNav => {
                if (contentNav.contains(e.target)) {
                    selectTab(contentNav, tabss, e);
                }
            });
        }
    }
};

const mainSlider = _ => {
    const quoteSlide = document.querySelector('.slide');
    if (quoteSlide) {
        const slider = new tns({
            container: '.slide',
            controls: false,
            navPosition: 'bottom',
            mouseDrag: true,
            loop: true,
            controlsContainer: '.sliderControls',
            lazyload: true,
            lazyloadSelector: 'img',
            preventScrollOnTouch: 'auto',
            responsive: {
                1440: {
                    arrowKeys: true,
                    controls: true,
                    controlsContainer: true,
                    prevButton: true,
                    nexButton: true,
                    speed: 900
                }
            }
        });
        Array.from(quoteSlide.querySelectorAll('img')).forEach(img =>
            img.classList.add('tns-lazy-img')
        );
    }
};

const anims = _ => {
    if (document.querySelector('#wrapper')) {
        const container = Array.from(document.querySelectorAll('.icon'));
        if (container.length) {
            container.forEach(icon => {
                const src = icon.getAttribute('data-src');

                lottie.loadAnimation({
                    container: icon,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: src
                });
            });
        }
    }
};

const lightbox = _ => {
    $('.play').fancybox({
        afterShow: (instance, slide) => {
            slide.$slide.find('video').attr('poster', '/assets/poster.png');
        }
    });
};

const setTransitionBg = $transitionContainer => {
    let screenWidth = window.innerWidth >= 769 ? '1920x1080' : '768x1024';
    const srcOut = `/assets/image/moving/anim_blau-logo_${screenWidth}.gif?${new Date().getTime()}`;
    $transitionContainer.css('background-image', `url(${srcOut})`);
};

const loadingAnimation = _ => {
    const loadingOverlay = jQuery('#loadingAnimation');
    const transition = jQuery('#loadingAnimation .transition');

    setTimeout(_ => {
        loadingOverlay.addClass('fadeOut');
        setTransitionBg(transition);
    }, 1500);
    setTimeout(_ => {
        loadingOverlay.addClass('hide');
    }, 3500);
};

export {
    smoothScroll,
    navigation,
    tabs,
    languageScroll,
    mainSlider,
    anims,
    lightbox,
    loadingAnimation
};
