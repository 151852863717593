import { toggleLang } from './general';
import { anims, languageScroll, lightbox, loadingAnimation, mainSlider, navigation, smoothScroll, tabs } from './main';
import { news } from './firstscreen';
import { lazyload } from './helper/lazyload/layzload';

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

jQuery(document).ready(_ => {
    languageScroll();
    news();
    anims();
    lazyload();
    mainSlider();
    smoothScroll();
    loadingAnimation();

    const notice = document.querySelector('#notice');
    const agree = document.querySelector('.agree');
    const body = document.querySelector('body');

    if (notice !== null) {
        if (Cookies.get('cookieNotice')) {
            notice.parentNode.removeChild(notice);
        } else {
            notice.classList.add('show');
            body.classList.add('cookie');
        }
    }

    agree.addEventListener('click', event => {
        event.preventDefault();
        notice.parentNode.removeChild(notice);
        body.classList.remove('cookie');
        Cookies.set('cookieNotice', 'accepted', {expires: 365});
    });

    jQuery(window).on('click', e => {
        tabs(e);
        toggleLang(e);
        lightbox();
        navigation(e);
    });

    jQuery("#covid19-notice span.close").on('click', e => {
        jQuery("#covid19-notice").hide();
    });

    jQuery("#ausschreibung div.close").on('click', e => {
        jQuery("#ausschreibung").hide();
        jQuery("#firstscreen").removeClass("ausschreibung");
    });

});
