const toggleLang = e => {
    const lang = Array.from(document.querySelectorAll('.language'));
    if (lang && e) {
        lang.forEach(l => {
            if (l.contains(e.target)) {
                if (document.querySelector('.languageSwitch').contains(e.target)) {
                    return false;
                }
                l.parentNode.classList.toggle('langOpen');
                l.classList.toggle('open');
            }
        });
    }
};

export { toggleLang };
